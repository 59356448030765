import * as React from "react"

import ToolScreen from '../../../../components/toolScreen'


const Tool = ({ data }) => (
  <ToolScreen
    name="InVision"
    img_name="invision_logo.png"
    link="https://www.invisionapp.com/"
    description="InVision is a great solution for designers that need to turn an existing static design into an interactive prototype."
    twitterHandleTool="@InVisionApp"
  />
)

export default Tool;


